import { graphql } from 'gatsby';
import { isExpired, getDisplayDateOnly, isPassed } from '../helper';
import React from 'react';
import {
  Feature,
  SiteMetadata,
  Tags,
  Button,
  ButtonsContainer,
  AddToCalendarButton,
  GoogleAds,
  Emoji,
  StreamingMedia,
  TypeButtons,
} from '../components';
import AmazonResponsive from '../components/Ads/AmazonResponsive';
import { useModal } from '../context';
import { Layout } from '../layouts/Layout';
import { OutboundLink } from "gatsby-plugin-google-analytics"

export default props => {
  const { data, location } = props;
  const {
    series,
    country,
    description,
    name,
    image,
    summary,
    tags,
    url,
    start,
    expiry,
  } = data.item.data;
  const navigation = location.state ? location.state.navigation : null;
  const { modal } = useModal();

  let cover, localFiles;
  if (image) {
    localFiles = image.localFiles;
    cover = localFiles && localFiles[0];
  }

  return (
    <Layout navigation={navigation}>
      <SiteMetadata
        title={name}
        description={summary}
        image={cover && cover.url}
        path={props.location.pathname}
      />
      <article className={modal && 'max-h-80vh md:max-h-90vh overflow-auto'}>
        <div className={modal ? 'p-4 lg:p-8' : 'container pt-8'}>
          <div className="w-full flex justify-center items-center">
            <AmazonResponsive className="lg:my-6" />
          </div>
          <h1 className="text-2xl lg:text-3xl text-indigo-500 font-bold leading-tight">
            <OutboundLink href={url} target="_blank" rel="noopener noreferrer">
              {name}
            </OutboundLink>
          </h1>
          <p className="text-base lg:text-lg text-indigo-800 font-medium mb-4">
            {summary}
          </p>
          <div className="flex flex-wrap mb-6">
            <div className="w-full pb-4 lg:w-3/5 lg:pr-4 lg:pb-0">
              <StreamingMedia item={data.item.data} />
            </div>
            <div className="w-full lg:w-2/5 lg:pl-4">
              <div className="mt-4 whitespace-pre-line text-sm lg:text-base leading-normal text-indigo-900 pb-6"
                dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.html }}>
              </div>
              <div className="w-full flex justify-center items-start">
                <div className="w-1/2">
                  <Feature label="Where?" value={country} />
                </div>
                <div className="w-1/2">
                  <Tags label="What?" value={tags} prefix='/streamings/' isLink />
                </div>
              </div>
              {series && <Tags label="Series" value={[series]} prefix='/series/' isLink />}
              <Feature label="Where can I watch?" value={url} />
              <div className="w-full flex justify-start items-start">
                <div className="w-1/2">
                  <Feature label="Starting From" value={start && !isPassed(start) ? getDisplayDateOnly(start) : 'Now'} />
                </div>
                {expiry && <div className="w-1/2">
                  <Feature label={isExpired(expiry) ? "Expired On" : "Expiring On"} value={getDisplayDateOnly(expiry)} />
                </div>}
              </div>
              <div className="w-full flex flex-col justify-center items-center md:items-start">
                <OutboundLink target="_blank" rel="noopener noreferrer"
                  className="inline-block text-sm mr-2 mb-2 rounded-lg px-4 py-2 text-white bg-indigo-600 hover:bg-indigo-500 font-semibold shadow-lg cursor-pointer"
                  href={url}>
                  <Emoji symbol="🎥"/> Watch Now
              </OutboundLink>
                {!isExpired(expiry) && <AddToCalendarButton
                  title={name}
                  location={url}
                  startTime={isPassed(start) ? undefined : start}
                  endTime={isPassed(expiry) ? undefined : expiry}
                  description={summary} />}
              </div>
            </div>
          </div>
          <ButtonsContainer>
            <Button to="/streamings?ref=single-item-button">
              See all streamings
            </Button>
          </ButtonsContainer>
          <TypeButtons title="You might also be interested in..."></TypeButtons>
          <GoogleAds slotId="9917897326" />
        </div>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query SingleItemQuery($slug: String!) {
    item: airtable(data: { slug: { eq: $slug } }) {
      data {
        country
        description {
          childMarkdownRemark {
            html
          }
        }
        name
        image {
          localFiles {
            url: publicURL
            childImageSharp {
              fluid(maxWidth: 733, maxHeight: 480, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        series
        slug
        summary
        tags
        url
        start
        expiry
      }
    }
  }
`;
