import React, { Component } from 'react';

const isClient = typeof window === 'object';

const defaultClassNames =
  'w-full h-full rounded-md overflow-hidden flex justify-center items-center';
// const mobile = 'block md:hidden';
// const smallScreen = 'hidden sm:block md:hidden lg:hidden xl:hidden'
// const largeScreen = 'hidden md:block';
const bannerVariant = {
  music: {
    small: {
      src:
        'https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=22&l=ur1&category=amazon_music_bounty&banner=067510TKVXTW7P45QMR2&f=ifr&linkID=836eab07a0b9cac5c167065989ab26bd&t=theshowsmustgoonline-21&tracking_id=theshowsmustgoonline-21',
      width: 250,
      height: 250,
    },
    large: {
      src:
        'https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=48&l=ur1&category=amazon_music_bounty&banner=1S7QPRHBCK6W9HRPHV82&f=ifr&linkID=ce6d267bcad02cb9cda99916864ba2f7&t=theshowsmustgoonline-21&tracking_id=theshowsmustgoonline-21',
      width: 728,
      height: 90,
    },
  },
  video: {
    small: {
      src:
        'https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=12&l=ur1&category=piv&banner=066SJ0SXHYEH1KTC9D82&f=ifr&linkID=76194cee509e53637ed18f84709eff49&t=theshowsmustgoonline-21&tracking_id=theshowsmustgoonline-21',
      width: 300,
      height: 250,
    },
    large: {
      src:
        'https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=48&l=ur1&category=piv&banner=1KBBZAT6W0H3QBGTQP82&f=ifr&linkID=cc9041d66d144a429832d4d41ab59ebf&t=theshowsmustgoonline-21&tracking_id=theshowsmustgoonline-21',
      width: 728,
      height: 90,
    },
  },
};

const calculateVariant = () => {
  const max = 100;
  const min = 1;
  const randomInteger = Math.floor(Math.random() * (max - min + 1)) + min;

  if (randomInteger <= 50) return 'music';
  else return 'video';
};

export const AmazonSquare = ({ className }) => {
  const banner = bannerVariant[calculateVariant()];

  return (<div className={`w-full sm:w-1/2 xl:w-1/3 px-3 xl:px-6 py-6 ${className}`}>
    <div className={`${defaultClassNames} bg-white shadow-sm`}>
      <iframe
        title="amazon-ads-mobile"
        src={banner.small.src}
        width={banner.small.width}
        height={banner.small.height}
        scrolling="no"
        border="0"
        marginWidth="0"
        frameBorder="0"
      ></iframe>
    </div>
  </div>)
}
export const AmazonBanner = ({ className }) => {
  const banner = bannerVariant[calculateVariant()];

  return (<div className={`w-full ${className}`}>
    <div className={defaultClassNames}>
      <iframe
        title="amazon-ads"
        src={banner.large.src}
        width={banner.large.width}
        height={banner.large.height}
        scrolling="no"
        border="0"
        marginWidth="0"
        frameBorder="0"
      ></iframe>
    </div>
  </div>)
}

export class AmazonResponsive extends Component {
  getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      width: undefined,
      height: undefined,
    };
  }

  handleResize() {
    const state = this.getSize();
    this.setState(state)
  }

  componentDidMount() {
    this.handleResize();
    // window.addEventListener('resize', this.handleResize);
  }

  componentDidunmount() {
    // window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const showDesktopAds = this.state.width >= 768;
    return (showDesktopAds ?
      <AmazonBanner className={this.props.className} /> :
      <AmazonSquare className={this.props.className} />)
  }
}

export default AmazonResponsive;
